import { useAuth } from "react-oidc-context"
import { useAuth0 } from '@auth0/auth0-react';
import { AuthMechanismService } from "./AuthMechanismService";

export const useAuthProxy = () => {
    const useProxiedAuthentication = AuthMechanismService.isAuth0() ? useAuth0 : useAuth;
    const context = useProxiedAuthentication();

    // assuming keycloak
    let authProxy = context;

    // override with auth0's names if not
    if (AuthMechanismService.isAuth0()) {
        authProxy = {
            ...context,
            signinRedirect: context.loginWithRedirect, 
            isAuthenticated: context.isAuthenticated, 
            isLoading: context.isLoading, 
            user: {
                ...context.user,
                profile: {
                    ...context.user
                }
            }, 
            signoutRedirect: context.logout
        };
    }

    return authProxy;
}