import { useEffect, useState } from "react";

export const AuthServices = {
    auth0: 'Auth0',
    keycloak: 'Keycloak'
};

const StorageKey = 'auth-service';

function get(key) {
    return window[key];
}

function set(key, value) {
    window[key] = value;
}

class ServiceTracker {
    constructor() {
        this.service = get(StorageKey) || AuthServices.auth0;
        this.subscribers = [];
    }

    setService = (service) => {
        this.service = service;
        set(StorageKey, service);

        this.subscribers.forEach(cb => cb(service));
    }

    getService = () => this.service;

    enableAuth0 = () => {
        this.setService(AuthServices.auth0);
    }

    enableKeycloak = () => {
        this.setService(AuthServices.keycloak);
    }

    isAuth0 = () => this.getService() === AuthServices.auth0;

    isKeycloak = () => this.getService() === AuthServices.keycloak;

    on = (cb) => {
        this.subscribers.push(cb);
        return () => {
            this.off(cb);
        }
    }

    off = (cb) => {
        const idx = this.subscribers.indexOf(cb);
        if (idx === -1) return;

        this.subscribers.splice(idx, 1);
    }
}

export const AuthMechanismService = new ServiceTracker();

export const useAuthMechanism = () => {
    const [service, setService] = useState(AuthMechanismService.getService());

    useEffect(() => {
        const unsubscribe = AuthMechanismService.on(setService);

        return unsubscribe;
    }, []);

    return service;
}