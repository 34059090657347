import { createRoot } from 'react-dom/client';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { useGetFrontendRedirectsQuery } from './app/services/api';
import { WebStorageStateStore } from 'oidc-client-ts';
import {
  AuthProvider as KeycloakProvider,
} from 'react-oidc-context';
import App from './App';

import './index.css';
import { AuthServices, useAuthMechanism } from './helpers/AuthMechanismService';
import { Auth0Provider } from '@auth0/auth0-react';

const oidcConfig = {
    authority: process.env.REACT_APP_KEYCLOAK_DOMAIN,
    client_id: process.env.REACT_APP_KEYCLOAK_REALM,
    redirect_uri: window.location.href,
    automaticSilentRenew: true,
    loadUserInfo: true,
    revokeTokensOnSignout: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };

const container = document.getElementById('root');
const root = createRoot(container);

const AuthProviderWithHistory = ({ children }) => {
	const { data, isFetching } = useGetFrontendRedirectsQuery();
	const navigate = useNavigate();
	const service = useAuthMechanism();

	if (isFetching) return null;

	const matchingRedirect = data?.find((el) => el.from.replace(/\/$/, '') === window.location.href.replace(/\/$/, ''));

	if (matchingRedirect) {
		window.location.replace(matchingRedirect.to);
		return null;
	}

	if (window.location.origin.indexOf('learn') === -1) {
		return children;
	}

	return service === AuthServices.keycloak ? (
		<KeycloakProvider
			key="keycloak"
			{...oidcConfig}
		>
			{children}
		</KeycloakProvider>
	) : (
		<Auth0Provider
			key="auth0"
			domain={process.env.REACT_APP_AUTH0_CLIENT_DOMAIN}
			clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
			redirectUri={window.location.origin}
			onRedirectCallback={(appState) => {
				if (appState?.returnTo) navigate(appState.returnTo);
			}}>
			{children}
		</Auth0Provider>
	);
};

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<AuthProviderWithHistory>
				<App />
			</AuthProviderWithHistory>
		</BrowserRouter>
	</Provider>,
);
