import React from 'react';
import { Helmet } from 'react-helmet'

import './styles.css';

const TransperfectLanguageSelector = () => {
  return (
    <Helmet>
      <script referrerPolicy="no-referrer-when-downgrade" type="text/javascript"
        src="https://www.onelink-edge.com/moxie.min.js" data-oljs="P6A3A-604F-B559-2AF9"></script>
    </Helmet>
  )
};

export default TransperfectLanguageSelector;
