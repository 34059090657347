import { useAuthProxy } from "../../helpers/useAuthProxy";

const LoginForm = (props) => {
	const { signoutRedirect, signinRedirect, user, isAuthenticated, isLoading } = useAuthProxy();

	return (
		<div className="container space-y-4 py-4">
			<h1>Keycloak Login Test</h1>

			{!isAuthenticated && !isLoading && (
				<button className="btn-primary" onClick={() => signinRedirect()}>
					Log In with Keycloak
				</button>
			)}
			{isAuthenticated && (
				<button className="btn-primary" onClick={() => signoutRedirect({ returnTo: window.location.origin })}>
					Log out
				</button>
			)}
			{user && (
				<div>
					<h4>You are logged in as: </h4>
					{Object.keys(user).map((k) => (
						<p>
							<b>{k}</b>: {JSON.stringify(user[k])}
						</p>
					))}
				</div>
			)}
		</div>
	);
};

export default LoginForm;
