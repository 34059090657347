import { useCallback, useEffect, useRef } from 'react';
import { useAuthProxy } from '../../helpers/useAuthProxy';
import { AuthMechanismService } from '../../helpers/AuthMechanismService';

let hasTriedAuth0 = false;

const SsoGate = ({ children }) => {
	const { signinRedirect, isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuthProxy();
	const signInRedirectTriggered = useRef(false);

	useEffect(() => {
		if (hasTriedAuth0) return;

		hasTriedAuth0 = true;
		(async () => {
			try {
				await getAccessTokenSilently();
			} catch (e) {
				AuthMechanismService.enableKeycloak();
			}
		})();
	}, [getAccessTokenSilently]);

	const redirect = useCallback(async () => {
		if (signInRedirectTriggered.current) return;

		signInRedirectTriggered.current = true;

		signinRedirect({
			login_hint: '/login',
		});
	}, [signinRedirect]);

	useEffect(() => {
		if (!user || !user.profile?.email) return;

		let freshpaint = window.freshpaint || {};

		console.log('firing freshpaint identify call', freshpaint);

		freshpaint.identify({ email: user.profile?.email });
	}, [user]);

	if (window.location.origin.includes('pixelsmith') || window.location.origin.includes('localhost')) return children;

	if (isLoading) return null;

	if (isAuthenticated) return children;

	if (AuthMechanismService.isKeycloak()) {
		// the timeout is needed because the keycloak redirect method triggers a state update,
		// which you oughtn't do during the render body
		setTimeout(redirect, 25);
	}

	return null;
};

export default SsoGate;
